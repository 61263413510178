<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog" v-if="month === current_month">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row class="py-2 dense-wrapper">
          <v-col cols="12" md="6">
            <date-component v-model="month" req label="Місяць" disabled/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select :items="services" filled label="Послуга"
                      hide-details
                      v-model="service_id"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="12">
            <ACC_ContractorContract :value="contract_id" @autocompleteChange="contractChange" :person_hash="person_hash"
                                    :contractor_name="flat_name"
                                    :class="contract_id ? '' : 'req-star'" color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Об’єм"
                          v-model.number="volume"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="volume ? '' : 'req-star'"
                          color="grey"
                          v-decimal
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Сума"
                          v-model.number="sum"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="sum ? '' : 'req-star'"
                          color="grey"
                          v-decimal
                          @change="changeSum"
            />
          </v-col>
          <v-col cols="6">
            <v-select :items="tax_select" filled label="Ставка ПДВ"
                      hide-details
                      v-model="tax_type"
                      @change="changeTaxInSum"
                      color="grey"/>
          </v-col>
          <v-col cols="6" class="d-flex">
            <v-switch v-model="tax_in_sum"
                      label="ПВД в сумі" color="success"
                      hide-details @change="changeTaxInSum"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="ПДВ"
                          v-model.number="tax"
                          color="grey"
                          v-decimal
                          @change="changeTax"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                type="text" hide-details filled label="Коментар"
                v-model="comment"
                color="grey"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="updateService" :loading="getModalLoading"
             :disabled="month !== current_month"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {SELECT_SERVICES} from "@/store/actions/services";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

import oneTimeChargeAPI from "@/utils/axios/one_time_charge";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";
import {round_number} from "@/utils/icons";
import {SELECT_TAXES_LIST_ELEMENTS} from "@/store/actions/accounting/list";
import {GET_ACCOUNTING_GENERAL_SETTINGS} from "@/store/actions/organization";

const modalDeleteId = 'one_time_legacy_modal_delete'

export default {
  name: "HWP_Modal_OneTime_Legacy",
  mixins: [ModalComponentMixin],
  components: {
    ACC_ContractorContract: () => import("@/components/accounting/autocomplite/modal/ACC_ContractorContract"),
  },
  data() {
    return {
      person_hash: this.item.person_hash || undefined,
      flat_name: this.item.flat_name || null,
      service_id: this.item.service_id || null,
      contract_id: this.item.contract_id || null,
      month: this.item.month,
      volume: this.item.volume,
      sum: this.item.sum || 0,
      tax: this.item.tax || 0,
      tax_type: this.item.tax_type || null,
      tax_in_sum: this.item.tax_in_sum || false,
      comment: this.item.comment || ''
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
      current_month: 'getCurrentMonthLegacy',
      tax_select: 'get_taxes_list_pdv_select',
      settings: 'getAccountingGeneralSettings',
      settings_home: 'getGeneralSettings',
    }),
  },
  methods: {
    ...mapActions({
      fetchServices: SELECT_SERVICES,
      fetchTaxSelect: SELECT_TAXES_LIST_ELEMENTS,
      fetchAccountingSettings: GET_ACCOUNTING_GENERAL_SETTINGS
    }),
    round_number,
    getTaxTariff(tax_id) {
      if (!tax_id) return null
      const tariffs = {}
      const pdv_without = this.settings?.pdv_without?.value || 0
      const pdv_0 = this.settings?.pdv_0?.value || 0
      const pdv_5 = this.settings?.pdv_5?.value || 0
      const pdv_7 = this.settings?.pdv_7?.value || 0
      const pdv_9 = this.settings?.pdv_9?.value || 0
      const pdv_20 = this.settings?.pdv_20?.value || 0

      tariffs[pdv_without] = null
      tariffs[pdv_0] = 0
      tariffs[pdv_5] = 5
      tariffs[pdv_7] = 7
      tariffs[pdv_9] = 9
      tariffs[pdv_20] = 20

      console.log(tariffs)

      return tariffs[tax_id] || null
    },
    calcRow(col = '') {
      const tax_tariff = this.getTaxTariff(this.tax_type)

      if (!this.sum) {
        this.sum = 0
        this.tax = 0
      }

      console.log(tax_tariff, this.tax_type, this.tax_in_sum, this.sum)

      if (col === 'tax') {
        if (this.tax_in_sum) {
          this.tax = this.round_number(this.sum / (100 / tax_tariff + 1), 2)
        } else {
          this.tax = this.round_number(this.sum * tax_tariff / 100, 2)
        }
        return;
      }

      if (this.tax_in_sum) {
        this.tax = this.round_number(this.sum / (100 / tax_tariff + 1), 2)
      } else {
        this.tax = this.round_number(this.sum * tax_tariff / 100, 2)
      }
    },
    contractChange(payload) {
      this.contract_id = payload?.value || null
    },
    changeTaxInSum() {
      this.$nextTick(() => {
        this.calcRow()
      })
    },
    changeTax() {
      this.$nextTick(() => {
        this.calcRow('tax')
      })
    },
    changeSum() {
      this.$nextTick(() => {
        this.calcRow('sum')
      })
    },
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.flat_name = this.item.flat_name
      this.person_hash = this.item.person_hash || null
      this.service_id = this.item.service_id || null
      this.contract_id = this.item.contract_id || null
      this.volume = this.item.volume
      this.month = this.item.month
      this.sum = this.item.sum || 0
      this.tax = this.item.tax || 0
      this.tax_type = this.item.tax_type || null
      this.tax_in_sum = this.item.tax_in_sum || false
      this.comment = this.item.comment

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення одноразового нарахування: ${this.getServiceName()}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateService() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Послуга, дата, та сума мають бути заповнені',
          color: 'error lighten-1'
        })
        return undefined
      }

      const payload = {
        person_hash: this.person_hash,
        service_id: this.service_id,
        contract_id: this.contract_id,
        month: this.month,
        volume: this.volume,
        sum: this.sum,
        tax: this.tax,
        tax_type: this.tax_type,
        tax_in_sum: this.tax_in_sum,
        comment: this.comment,
      }

      this.$store.commit(START_MODAL_LOADING)

      if (this.isNew) {
        oneTimeChargeAPI.create_by_flat_legacy(payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.closeModal({ operation: 'fetch' })
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
          .finally(() => {
            this.$store.commit(STOP_MODAL_LOADING)
          })
      } else {
        payload['id'] = this.itemId
        oneTimeChargeAPI.update_by_flat_legacy(payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.closeModal({ operation: 'fetch' })
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
          .finally(() => {
            this.$store.commit(STOP_MODAL_LOADING)
          })
      }
    },
    getServiceName() {
      if (this.service_id) {
        const ind = this.services.find(item => item.value === this.service_id)
        if (ind) {
          return ind.text
        }
      }
      return ''
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.flat_name = payload.flat_name
              this.person_hash = payload.person_hash
              this.contract_id = payload.contract_id
              this.service_id = payload.service_id
              this.month = payload.month
              this.volume = payload.volume
              this.sum = payload.sum
              this.tax = payload.tax
              this.tax_type = payload.tax_type
              this.tax_in_sum = payload.tax_in_sum
              this.comment = payload.comment

              if (!this.itemId && !this.tax_type) {
                this.tax_id = this.settings.pdv_without?.value
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.commit(START_MODAL_LOADING)
                  oneTimeChargeAPI.remove_by_flat_legacy(this.itemId)
                    .then(response => response.data)
                    .then(data => {
                      if (data) {
                        this.$store.commit(STOP_MODAL_LOADING)
                        this.closeModal({ operation: 'fetch' })
                      }
                    })
                    .catch(err => {
                      const error = err.response.data.detail;
                      this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    })
                    .finally(() => {
                      this.$store.commit(STOP_MODAL_LOADING)
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  created() {
    this.fetchServices()
    this.fetchTaxSelect()
    this.fetchAccountingSettings()
  }
}
</script>
